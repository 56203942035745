import React, { useEffect, useState } from "react";
import Bounce from 'react-reveal/Bounce'
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player/youtube'

const VideoView = (props) => {
  const [videoUrl, setVideoUrl] = useState('')
  // useEffect(() => {
  //   if(props?.data?.videoUrl?.length > 0){
  //     const video = new URL(props?.data?.videoUrl)?.searchParams
  //     console.log(video)
  //     if(video?.get('v') && !video?.get('list')){
  //       setVideoUrl(`https://www.youtube.com/embed/${video.get('v')}?autoplay=1`)
  //     }
  //     if(video?.get('list')){
  //       setVideoUrl(`http://www.youtube.com/embed?listType=playlist&list=${video?.get('list')}&autoplay=1`)
  //       console.log(videoUrl);
  //     }
  //   } else {
  //     console.log('no video link');
  //   }
  // })

  return(
    
    <div style={{color:'black', width:'100vw', height:'100vh'}}>
      <ReactPlayer width="100%" height="100%" controlls={true} playing={true} url={props?.data?.videoUrl} />
    </div>
  )
}

export default VideoView