import React, { useState, useEffect, useRef } from "react";
import Bounce from 'react-reveal/Bounce';
import styled, { keyframes } from 'styled-components'
import socketIOClient from "socket.io-client";
import ReactPlayer from 'react-player/youtube'
import { Config } from '../../../../Config';
import { bounce } from 'react-animations';

const TextWrapper = styled.div`
justify-content: center;
align-self: center;

text-align: center;
display: flex;
padding: 0px;
margin:0px;
position: absolute;
flex-direction: column;
`
const Text = styled.h1`
  /* background:linear-gradient(45deg, #FF161B -20.01%, #580DBF 20.99%); */
  /* linear-gradient(25.98deg, #FF161B -0.01%, #580DBF 99.99%) */
  text-transform: uppercase;
  /* word-break: break-all;
  word-wrap: wrap; */
  width:50vw;
  background-clip: text;
  font-size:10vw;
  color: black;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0px;
  padding: 0px;
  font-family: AdigianaExtreme;
  font-weight: 900;
  text-shadow: 0 0 20px white, 0 0 20px white;
`
const TextSum = styled.h1`
  /* background:linear-gradient(45deg, #FF161B -20.01%, #580DBF 20.99%); */
  /* linear-gradient(25.98deg, #FF161B -0.01%, #580DBF 99.99%) */
  text-transform: uppercase;
  /* word-break: break-all; */
  word-wrap: wrap;
  background-clip: text;
  font-size: 208px;
  color: white;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0px;
  padding: 0px;
  position: absolute;
  right: -15vw;
  bottom:-20vw;
  text-shadow: 0 0 20px black, 0 0 20px black;
  /* transform: rotate(-20deg); */
  font-family: KOMIKAX_;
  font-weight: 900;
`
const Currency = styled.h1`
text-transform: uppercase;
  /* word-break: break-all; */
  word-wrap: wrap;
  background-clip: text;
  font-size: 208px;
  color: white;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:0px;
  padding: 0px;
  text-shadow: 0 0 20px black, 0 0 20px black;
  /* transform: rotate(-20deg); */
  font-family: KOMIKAX_;
  font-weight: 900;

`
const DepViewBox = styled.div`
color:black;
max-width:100vw;
height:100vh;
display:flex;
justify-content: center;
align-items: center;
overflow: hidden !important;

`
const Bounce1 = styled.div`
  animation: 2s ${keyframes`${bounce}`} infinite;
`;


const DepView = (props) => {
  const [animation, setAnimation] = useState(false)
  const [sum, setSum] = useState(false)
  const [video, setVideo] = useState(null)
  const [playing, setPlaying] = useState(true)
  const [data, setData] = useState(null)
  const socket = useRef(null)

  useEffect(() => {
    setInterval(() => {
      console.log('update');
    },30000)
    socket.current = socketIOClient(Config.socket_url, {
      transports: ["websocket"]
    });
    socket.current.emit("join_screen");
    socket.current.on("start_dep_view", async (data) => {
      setData(data)
      // setView('dep-view')
      await setAnimation(false)
      await setSum(false)
      await setPlaying(false)
      await setAnimation(true)
      await setVideo(data?.video)
      setTimeout(() => {
        setSum(true)
      }, 1500)

      setTimeout(() => {
        setSum(false)
      }, 13000)

      setTimeout(() => {
        setAnimation(false)
      }, 14000)
      setTimeout(() => {
        setSum(false)
        setPlaying(true)
      }, 16000)
    })
    socket.current.on("start_video", async (data) => {
      setData(data)
      // setView('video-view')
      if(data?.videoUrl){
        await localStorage.setItem('video', data?.videoUrl)
        await setAnimation(false)
        await setSum(false)
        await setPlaying(false)
        await setAnimation(true)
        await setPlaying(true)
      }
      console.log(data?.videoUrl);
    })
  },[])


  console.log(props);
  
  return(
    <DepViewBox >
      <TextWrapper>
      <Bounce opposite when={animation}>
          <Text length={data?.name?.length}>
            {data?.name}
          </Text>
        </Bounce>
        <Bounce opposite when={sum}>
          <Bounce1>
            <TextSum>
              {data?.sum} 
              <Currency>$</Currency>
            </TextSum>
          </Bounce1>
        </Bounce>
      </TextWrapper>
      {!playing ? 
        <>
          <video
            src={`var${video ? video : 1}.mp4`}
            type="video/mp4" 
            id="video1"
            style={{width:"100vw", height:"100vh"}}
            autoPlay
            muted
          />
          <audio
            id="audio1"
            autoPlay
            type="audio/mp3"
            src={
              data?.audioSrc !== null ?
                `${Config.socket_url}${data?.audioSrc}`
              : 
                `${Config.socket_url}current/current.mp3`
              }
          />
        </>
      : null}
      <div style={{position:'absolute',color:'black', width:'100vw', height:'100vh', zIndex:playing ? 2 : -1}}>
        <ReactPlayer 
        
        width="100%" height="100%"  playing={playing} url={localStorage.getItem('video')} />

      </div>

</DepViewBox>
  )
}

export default DepView