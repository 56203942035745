import React, { useEffect, useRef, useState } from "react";
import socketIOClient from "socket.io-client";
import { Config } from '../../Config';
import Url from 'url-parse';
import {
  Container,
  InputInfoWrapper,
  InputVideoWrapper,
  Button,
  Video,
  InputAudio,
  AnimationsPrev
} from './styles.js'
import Input from './Input'
import axios from 'axios'



const videos = [
  {
    id:1,
    name:'var1.mp4',
  },
  {
    id:2,
    name:'var2.mp4',
  },
  {
    id:3,
    name:'var3.mp4',
  },
  {
    id:4,
    name:'var4.mp4',
  },
  {
    id:5,
    name:'var5.mp4',
  },
]

const AdminScreen = () => {
  const socket = useRef(null)
  const [name, setName] = useState('')
  const [sum, setSum] = useState('')
  const [url, setUrl] = useState('')
  const [file, setFile] = useState([])
  const [selected, setSelected] = useState(null)
  const [err, setErr] = useState('')
  const [errName, setErrName] = useState('')
  const [errDep, setErrDep] = useState('')
  const [errAudio, setErrAudio] = useState('')
  const [errVideo, setErrVideo] = useState('')
  const [stateComponent, setStateComponent] = React.useState({
    files: [],
    uploading: false
  });

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      // 'Authorization': `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods" : "GET,POST,PUT,DELETE,OPTIONS",
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
    },
  }
  
  const _handleImageChange = async (e) => {
    setErrAudio('')
    e.preventDefault();
    console.log(e?.target?.files);
    const reader = new FileReader();
    const audio = await e?.target?.files[0]
    if(audio?.type !== 'audio/mpeg' && audio?.type !== 'audio/mp3'){
      console.log(audio);
      setErrAudio('Аудио файл должен быть mp3')
      return
    }
    if(audio){
      localStorage.setItem('currentAudio', audio?.name)
      reader.onloadend = () => {
      setFile(audio)
      // setImgPrev(reader.result)
      }
      console.log(audio);
      reader.readAsDataURL(audio)
      const data = await {file}
      const formData = new FormData();
      formData.append('audio', audio);
      // const toSend = await toFormData(data)
      await axios.post(`${Config.socket_url}upload`, formData, config)
      .then((res) => {
        console.log(res)
        localStorage.setItem('audio', res.data)
      })
      .catch(e => console.log(e))
    }
  }
  

  const start_video = () => {
    setErrVideo('')
    if(!url){
      setErrVideo('Введите cсылку')
      return
    }
    const data = {
      videoUrl:url,
    }
    socket.current.emit('start_video', data)
  }

  const startDepView = () => {
    setErrName('')
    setErrDep('')
    setErrAudio('')
    // if(!localStorage.getItem('audio')){
    //   setErrAudio('Нет сохраненного трека')
    //   return
    // }
    if(!name){
      setErrName('Введите имя')
      return
    }
    if(name.length > 25){
      setErrName('Имя не должно быть длиннее 25 символов')
      return
    }
    if(sum.length > 8){
      setErrDep('Сумма не должна быть длиннее 8 символов')
      return
    }
    if(!sum){
      setErrDep('Введите сумму')
      return
    }
      const obj = []
      stateComponent.files.map((f, index) => {
        const arr = {
          filename: f.name,
          file: f
        }
        return obj.push(arr)
      })
      const data = {
        name:name,
        sum:sum,
        video:selected,
        audioSrc:localStorage.getItem('audio')
      }
      
    socket.current.emit('start_dep_view',data)
    
  }

  useEffect(() => {
    setInterval(() => {
      console.log('update');
    },30000)
    socket.current = socketIOClient(Config.socket_url, {
      transports: ["websocket"]
    });
  },[])
  useEffect(() =>{
    console.log(selected);

  },[selected])
  return(
    <Container style={{color:'black'}}>
      <InputInfoWrapper>
      <div style={{width:'370px'}}>
        <Input 
          value={name}
          onChange={setName}
          title={'Имя'}
          error={errName}
        />
        <Input 
          value={sum}
          onChange={setSum}
          title='Сумма'
          error={errDep}
        />
        
        
        </div>
        <div>
          <AnimationsPrev >
            {videos?.map((item) => (
              <Video style={{
                width:'100px',
                height:'100px',
                margin:'10px',
                // border: `${selected === item?.id ? '5px solid #59BE35' : null}`,
              }}
                selected={selected === item?.id}
                onClick={() => setSelected(item?.id)}
                id={item?.id}
                autoPlay
                muted
                loop="loop"
                src={item?.name}
                type="video/mp4"
              />

            ))}
            
          </AnimationsPrev>
          <label htmlFor='set-file'>
          {/* <div style={{color:'white'}}>dedsadsadsadsaap</div> */}
          
            <div style={{color:'white', width:'300px', marginBottom:'20px', wordBreak:'break-all'}}>
              {file?.name || localStorage.getItem('currentAudio')}
            </div>
            <InputAudio >
              Выберите аудио файл
            </InputAudio>
            <div style={{color:'red', width:'370px', marginBottom:'20px'}}>
            {errAudio}
          </div>
            </label>
              <input style={{display:'none'}} id='set-file' type='file' onChange={_handleImageChange}  accept="audio/*"/>
          
            <Button onClick={() => startDepView()}>Старт Анимации</Button>
          <div style={{color:'white', width:'370px', marginTop:'20px'}}>
          <Input 
          value={url}
          onChange={setUrl}
          title='Ссылка на видео или плейлист'
          error={errVideo}
        />
        <Button onClick={() => start_video()}>video</Button>
        </div>
        </div>
      </InputInfoWrapper>
    </Container>
  )
}

export default AdminScreen
