import React, { useEffect, useRef, useState } from 'react'
import socketIOClient from "socket.io-client";
import {Config} from '../../Config';
import DepView from './states/DepView';
import VideoView from './states/VideoView';
import Bounce from 'react-reveal/Bounce'

const MainScreen = () => {
  const currentComponentRef = useRef(null)
  const socket = useRef(null)
  const [data, setData] = useState(null)
  const [visible, setVisible] = useState(false)
  const [view, setView] = useState(<VideoView ref={currentComponentRef} socket={socket.current} />)
  
  
  const lib = {
    'dep-view': <DepView ref={currentComponentRef} socket={socket.current} data={data} />,
    'video-view': <VideoView ref={currentComponentRef} socket={socket.current} data={data} />,
  }

  useEffect(() => {
    socket.current = socketIOClient(Config.socket_url, {
      transports: ["websocket"]
    });
    socket.current.emit("join_screen");
    socket.current.on("start_dep_view", (data) => {
      setData(data)
      setView('dep-view')
    })
    socket.current.on("start_video", (data) => {
      setData(data)
      setView('video-view')
    })
  },[])
    
  return  (
    <DepView />
  )
}
export default MainScreen

