import { createGlobalStyle } from 'styled-components'
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AdminScreen from './Screens/AdminScreen'
import MainScreen from './Screens/MainScreen'
import { Route, Switch, BrowserRouter as Router,  } from 'react-router-dom'
import './index.css'


const GlobalStyles = createGlobalStyle`
html{
  width: 100%;
  min-height:100%;
  position: relative; 
  /* overflow: hidden !important; */
}
body{
  margin:0;
  background-color: black;
  color:'white';
  font-family: Inter italic;
  overflow: hidden !important;
}
`

ReactDOM.render(
  <>
    <GlobalStyles/>
    <Router>
      <Switch>
        <Route path="/admin" component={AdminScreen} />
        <Route path="/screen" component={MainScreen} />
      </Switch>
    </Router>
  </>
  ,document.getElementById('root')
);

reportWebVitals();
