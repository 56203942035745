import React, { useEffect, useRef } from "react";
import styled from 'styled-components'
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 120px;
  color: white;

`
const InputCustom = styled.input`
  width: ${(props) =>
    (props.width ? props.width : props.small ? '209' : '370') + 'px'};
  height: ${props => props.height ? props.height : '50px'};
  margin: ${props => props.margin ? props.margin  : '0 auto'};
  padding: 20px 24px 22px 28px;
  font-size: 16px;
  font-weight: normal;
  background-color: rgba(38, 80, 104, 1);
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
  white-space: nowrap;
  box-sizing: border-box;
  padding-left: ${props => props?.padLeft ? props.padLeft : '30px'};
  &::placeholder {
    /* color: rgba(255, 255, 255, 0.4); */
    font-size:14px;
  }

`
const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 10px;
`
const Error = styled.div`
  color:red;
  margin-top: 5px;

`
const Input = ({title, value, onChange, error}) => {
  return(
    <InputWrapper>
      <Title>{title}</Title>
      <InputCustom
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={title}
      
      />
      {error ? 
        <Error>
          {error}
        </Error>
      :
        null
      }
    </InputWrapper>
  )
}
export default Input